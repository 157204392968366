import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import img1 from './assets/background-images/01.png'
import img2 from './assets/background-images/02.png'
import img3 from './assets/background-images/03.png'
import img4 from './assets/background-images/04.png'
import defaultimg from './assets/style2_default.jpg';

import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
// const styleCode =  '2';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const App = () => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [styleCode, setStyleCode] = useState('1');
  const query = useQuery();
  const projectcode = query.get('projectcode') || 'images'; // Default to '101' if not specified

  const [showCurrentImage, setShowCurrentImage] = useState(false);
  useEffect(() => {
    let timer;
    if (showCurrentImage) {
      timer = setTimeout(() => {
        setShowCurrentImage(false);
        setCurrentImage(defaultimg);
      }, 10000); // 10 seconds
    }

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [showCurrentImage]);


  useEffect(() => {
    const fetchStyleCode = async () => {
      try {
        const response = await axios.post("https://stageapi.expoplan.in/api/get-exhibitio-style", {
          
            exb_code: projectcode
          
        });
        const newStyleCode = response.data.style;
        console.log("checking",newStyleCode)
        if (newStyleCode == 'Style-2'){
          setStyleCode('2')
        }
        

      } catch (error) {
        console.log(error);
      }
    };

    fetchStyleCode();
  }, [projectcode]);


  useEffect(() => {

    const fetchImages = async () => {
      console.log("fetch")
      try {
        
        const response = await axios.get("https://viewerapi.expoplan.in/api/images",{
          params: {
            projectcode: projectcode
          }
          });
        const newImages = response.data;
        setImages((prevImages) => {
          console.log("set")
    
          // Filter out the duplicate images
          const uniqueImages = newImages.filter(
            (newImage) => !prevImages.some((prevImage) => prevImage === newImage)
          );
          if (uniqueImages.length > 0) {
            console.log("get unique image")
            const latestImage = uniqueImages[uniqueImages.length - 1];
            setCurrentImage(latestImage);
            setShowCurrentImage(true)

            setTimeout(() => {
              // let id = document.getElementById('my')
              // id.classList.add('zoom-out')
              // set zoomout duration here >>
              setCurrentImage(null);
            }, 12000);
          }
          return [...prevImages, ...uniqueImages];
        });
      } catch (error) {
        console.log(error);
      }
    };



    const interval = setInterval(() => {
      fetchImages();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // const fetchImages = async () => {
  //   try {
  //     const response = await axios.get("http://localhost:3001/api/images");
  //     const newImages = response.data;
  //     const newUrls = newImages.map((image) => image);
  //     const uniqueUrls = [...new Set([...images, ...newUrls])];
  //     setImages(uniqueUrls);

  //     const latestImage = newImages[newImages.length - 1];
  //     if (latestImage && !images.includes(latestImage)) {
  //       setCurrentImage(latestImage);

  //       setTimeout(() => {
  //         setCurrentImage(null);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const imageArray = [img1, img2, img3, img4];

  const renderImageCards = () => {
    return images.map((image, index) => (
      <div key={index} className="card">
        <div className="card-image">
          <img src={image} alt="Image" />
        </div>
      </div>
    ));
  };
  const renderstyle1= ()=>(
    <>

    {currentImage && (
      <div className="fullscreen-image">
        <img className="zoom-out" id="my" src={currentImage} alt="Current Image" />
      </div>
    )}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <h1 style={{ margin: '0px auto',color:"white", fontFamily: "sans-serif" , fontSize:"50px"}}>Welcome</h1>
    </div>
    <div className="image-grid">
      {renderImageCards()}
    </div>
  </>
  )

  const renderStyle2 = () => (
    <>
      { (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <img
            src={showCurrentImage ? currentImage : defaultimg}
            alt="Current Image"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
      )}
    </>
  );
  


  return (
 

<div>
{styleCode === '1' ? renderstyle1() : renderStyle2()}
</div>
  );
};

export default App;
